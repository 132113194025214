import React from "react";

export const GridElements = (props) => {
    return (
        <div id="gridElements" className="text-center color-light">
            <div className="container">
            <div className="row d-flex-start">
                {props.data
                    ? props.data.line.map((d, i) => (
                        <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                            {" "}
                            <i className={d.icon}></i>
                            <div className="service-desc">
                                <h3>{d.name}</h3>
                                <p>{d.text}</p>
                            </div>
                        </div>
                    ))
                    : "loading"}
            </div>
            </div>
        </div>
    );
};
