import React from "react";

export const PageTitle = (props) => {
 
    return (
        <div id="titlepage">
            <div className="intro3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-xs-12 intro-text">
                                <div>
                                    <h1 className="upp color-header">
                                        {props.title}
                                        <span></span>
                                    </h1>
                                    <h1 className="upp color-header">
                                        {props.title2}
                                        <span></span>
                                    </h1>
                                    <h1>
                                        {props.title3}
                                        <span></span>
                                    </h1>
                                    <h1 className="upp">
                                        {props.title4}
                                        <span></span>
                                    </h1>
                                    <p>
                                        {props.sub}
                                        <span></span>
                                    </p>
                                </div>
                                <div>
                                   
                                    <p className="sub2">
                                       {props.sub2}
                                        <span></span>
                                    </p>
                                </div>

                                {" "}
                            </div>
                            <div className="col-md-6 col-xs-12 col-md-offset-2">
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
};
