import React from "react";
import { useState } from "react";
import emailjs from "emailjs-com";

const initialState = {
    name: "",
    email: "",
    message: "",
};

const Message = () => {
    const [{ name, email, message }, setState] = useState(initialState);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);

        emailjs
            .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
    <div id="form" className="row">
        <div className="col-xs-12 col-md-6">
            <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row d-flex">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={handleChange}
                            />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                required
                                onChange={handleChange}
                            />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                id="telefone"
                                name="tel"
                                className="form-control"
                                placeholder="WhatsApp"
                                required
                                onChange={handleChange}
                            />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                            <textarea
                                name="message"
                                id="message"
                                className="form-control"
                                rows="4"
                                placeholder="Message"
                                required
                                onChange={handleChange}
                            ></textarea>
                            <p className="help-block text-danger"></p>
                        </div>
                        <div id="success"></div>
                        <button type="submit" className="btn btn-custom btn-lg">
                            Enviar Mensagem
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div className="col-xs-12 col-md-6">
            <div className="col-xs-12 col-md-6">
                {" "}
                <h1 className="upp">Nos</h1>
                <h1 className="upp-color">Contate!</h1>
            </div>
        </div>
    </div>
    )
};

export default Message;